import { render, staticRenderFns } from "./ValuesCard.vue?vue&type=template&id=72b2cfca&scoped=true&"
import script from "./ValuesCard.vue?vue&type=script&lang=js&"
export * from "./ValuesCard.vue?vue&type=script&lang=js&"
import style0 from "./ValuesCard.vue?vue&type=style&index=0&id=72b2cfca&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72b2cfca",
  null
  
)

export default component.exports