<template>
  <div>
    <window-height>
      <container-section :container-class="'container md:my-auto items-center md:items-start'">
        <slide-one
          class="h-100 items-center"
          :slideOneTitleOne="'Its not what'"
          :slideOneTitleTwo="'we achieve, its what'"
          :slideOneTitleThree="'we overcome.'"
          :slideOneSideImage="'office.gif'"
        />
      </container-section>
    </window-height>

    <window-height>
      <container-section
        data-aos="fade-in"
        data-aos-delay="50"
        :containerClass="'container-fluid'"
        :sectionHeight="false"
        :backgroundColor="''"
        :imageName="'hands.webp'"
      >
        <div class="container">
          <!-- <div class="slide-title-large text-white w-3/5 pt-28">
            Let's create something together
          </div>-->
        </div>
      </container-section>
    </window-height>

    <container-section>
      <div class="our-values py-32">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div data-aos="fade-up" data-aos-delay="300" class="slide-title font-black">Our Values.</div>
            <div class="mt-20">
              <values-card
                :title="'Learn and Share'"
                :text="'Every Friday learn new concepts from the fellow developers. Share your learnings and develop your skills.'"
                :id="1"
              />
            </div>
          </div>

          <div class="col-sm-12 col-md-6 flex items-end">
            <div class="office-pana">
              <img
                data-aos="zoom-in-right"
                data-aos-delay="400"
                src="../assets/images/office-pana.svg"
                alt
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-6" v-for="item in ourValuesData" :key="item.id">
            <values-card :title="item.title" :text="item.text" :id="item.id" />
          </div>
        </div>
      </div>
    </container-section>

    <window-height>
      <container-section
        :containerClass="'container-fluid'"
        :sectionHeight="false"
        :backgroundColor="'#000000'"
      >
        <div class="container">
          <div
            data-aos="fade-right"
            data-aos-delay="300"
            class="slide-title-xl text-white md:w-3/5 pt-32 sm:pt-44 animate__animated animate__slideInRight"
          >Right people find the right place.</div>
        </div>
      </container-section>
    </window-height>

    <container-section>
      <!--<div class="openings">
        <div class="slide-title">Openings.</div>
        <div class="dropDown sm:w-1/4 py-2">
          <input-drop-down />
        </div>
        <div class="career-cards pt-5 " >
          <div class="row flex justify-center">
            <div class="col-sm-12 col-md-6 mb-4 " v-for="item in jobOpeningsData" :key="item.id">
            <card-component
            :title="item.title" :description="item.text" />
          </div>
          </div>
        </div>
      </div>-->

      <!-- <div class="ourServices mt-5  py-28">
        <div class="slide-title font-black pt-5 pb-5 w-3/5">Are you looking for services <br>like ours?</div>
        <base-button :href="'/contact-us'" id="GetInTouchoneEmail" class="btn-primary-dark" :isBorderRadiud="false">Get in Touch</base-button>
      </div>-->
    </container-section>
    <container-section :containerClass="'container-fluid'" :sectionHeight="false">
      <div class="container ourServices mt-5 py-16 pt-16">
        <div
          data-aos="fade-up"
          data-aos-delay="300"
          class="slide-title font-black"
        >Are you looking for services</div>
        <div data-aos="fade-up" data-aos-delay="350" class="slide-title font-black pb-5">like ours?</div>
        <base-button
          data-aos="fade-right"
          data-aos-delay="300"
          :href="'/contact-us'"
          id="GetInTouchoneAbout"
          class="btn-primary-dark"
          :isBorderRadiud="false"
        >Get in Touch</base-button>
      </div>
      <div class="about-footer relative">
        <Footer></Footer>
      </div>
    </container-section>
  </div>
</template>

<script>
//import CardComponent from '../components/AboutUs/CardComponent.vue'
import BaseButton from "../components/BaseButton.vue";
import ValuesCard from "../components/Careers/ValuesCard.vue";
import ContainerSection from "../components/ContainerSection.vue";
import Footer from "../components/Home/Footer.vue";
import SlideOne from "../components/Home/SlideOne.vue";
import WindowHeight from "../components/WindowHeight.vue";
//import InputDropDown from '../components/InputDropDown.vue'
import DataServies from "../services/DataService";

export default {
  title: "Magure - Careers",

  components: {
    ContainerSection,
    SlideOne,
    ValuesCard,
    //CardComponent,
    BaseButton,
    Footer,
    WindowHeight
    //InputDropDown
  },

  data() {
    return {
      jobOpeningsData: DataServies.jobOpenings,
      ourValuesData: DataServies.careersValues
    };
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/Pages/Careers.scss";
</style>