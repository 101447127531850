<template>
  <div class="value-card w-5/6 md:w-3/5 mt-12">
    <div data-aos="fade-up" data-aos-delay="300" class="title font-black">{{title}}</div>
    <hr class="my-0 border-line" data-aos="fade-right" data-aos-delay="350" />
    <div
      data-aos="fade-up"
      data-aos-delay="400"
      class="pt-4 text-light-gray text-para h-48 md:h-40 w-4/5"
    >{{text}}</div>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    title: String,
    text: String
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/Variables.scss";

.title {
  font-size: 2em;
  padding-bottom: 0.5em;
}
.text-para {
  color: $gray-dark;
  font-family: $font-circularStd-book !important;
}
@media (max-width: 500px) {
  .value-card {
    margin-top: 1em !important;
  }
}
</style>